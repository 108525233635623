module controllers {
    export module manifest {
        interface IManifestCrewScope extends ng.IScope {
        }

        interface IManifestCrewParams extends ng.ui.IStateParamsService {
            macId: number;
            manId: number;
        }

        export class manifestCrewUpdateCtrl {
            static $inject = ["$scope", '$rootScope', 'generalService', '$q', '$state', "$stateParams", '$timeout', "$anchorScroll", 'bsLoadingOverlayService', "manifestCrewService", "crewService", "menuService"];

            breadCrumbDesc: string = "";
            selectedTab: number = 0;

            manifestCrew: interfaces.manifest.IManifestCrew;
            manifestCrewList: interfaces.manifest.IManifestCrew[];

            constructor(private $scope: IManifestCrewScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private $state: ng.ui.IStateService,
                private $stateParams: IManifestCrewParams,
                private $timeout: ng.ITimeoutService,
                private $anchorScroll: ng.IAnchorScrollService,
                private bsLoadingOverlayService: interfaces.applicationcore.IbsLoadingOverlayService,
                private manifestCrewService: interfaces.manifest.IManifestCrewService,
                private crewService: interfaces.master.ICrewService,
                private menuService: interfaces.applicationcore.IMenuService) {

                bsLoadingOverlayService.wrap({
                    referenceId: 'update.crew'
                }, () => {
                    return this.loadManifestCrew();
                });
            }
            
            loadManifestCrew() {
                return this.manifestCrewService.get(this.$stateParams.macId, this.$stateParams.manId).get((result: interfaces.manifest.IManifestCrew) => {
                    this.manifestCrew = result;

                    if (result.Name && result.Surname) {
                        this.breadCrumbDesc = "Crew Member: " + result.Name + " " + result.Surname;
                    }

                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
            }

            loadCrewMembers(searchText) {
                return this.crewService.getForDropdown(this.manifestCrew.OwnerEntityId, searchText).query(() => {

                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            crewChanged(model: interfaces.applicationcore.IDropdownModel) {
                if (model) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'update.crew'
                    }, () => {
                        return this.loadCrewDefaults(model.Id).then((result) => {

                            this.manifestCrewList = [];

                            if (result.Name && result.Surname) {
                                this.breadCrumbDesc = "Crew Member: " + result.Name + " " + result.Surname;
                            }

                            this.manifestCrew.Crew = <interfaces.applicationcore.IDropdownModel>{
                                Id: result.Id,
                                Code: result.Name,
                                Description: result.Surname,
                                Display: (result.Name + " " + result.Surname),
                                Selected: true
                            };

                            this.manifestCrew.Name = result.Name;
                            this.manifestCrew.Surname = result.Surname;
                            this.manifestCrew.DateOfBirth = result.DateOfBirth;
                            this.manifestCrew.Gender = result.Gender;
                            this.manifestCrew.DocumentNumber = result.DocumentNumber;
                            this.manifestCrew.DocumentExpiry = result.DocumentExpiry;
                            this.manifestCrew.DocumentIssuedCountryCode = result.DocumentIssuedCountryCode;
                            this.manifestCrew.DocumentIssuedCountry = result.DocumentIssuedCountry;
                            this.manifestCrew.IDNumber = result.IDNumber;
                            this.manifestCrew.Occupation = result.Occupation;
                            this.manifestCrew.Movement = result.Movement;
                            this.manifestCrew.TravelerType = result.TravelerType;
                            this.manifestCrew.ResidenceCountryCode = result.ResidenceCountryCode;
                            this.manifestCrew.ResidenceCountryDescription = result.ResidenceCountryDescription;
                            this.manifestCrew.CountryCode = result.CountryCode;
                            this.manifestCrew.Country = result.Country;

                        });
                    });
                }
            }

            loadCrewDefaults(crwId: number) {
                return this.crewService.get(crwId).get(() => {

                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            EditCrew() {
                if (this.manifestCrew.Crew) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'update.crew'
                    }, () => {
                        return this.menuService.getGTSConnectMenuItem(318).get((result: interfaces.applicationcore.IMenu) => {
                            if (result.url) {
                                result.url += "?Id=" + this.manifestCrew.Crew.Id;
                                var url = this.$state.href("auth.IFrame", { url: result.url });
                                window.open(url, '_blank');
                            }
                        }).$promise;
                    });
                }
            }

            SaveCrew() {
                return this.manifestCrewService.save().save((this.manifestCrew), (result: interfaces.applicationcore.IMessageHandler) => {

                    this.$anchorScroll("topAnchor");
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    this.$anchorScroll("topAnchor");
                }).$promise;
            }

            //Submit/Update
            Save() {
                //Call Update Function
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'update.crew'
                }, () => {
                    return this.SaveCrew().then((result) => {

                        this.generalService.displayMessageHandler(result);

                        if (!result.HasErrorMessage) {
                            this.$timeout(() => {
                                this.bsLoadingOverlayService.wrap({
                                    referenceId: 'update.crew'
                                }, () => {
                                    if (this.$state.params["macId"]) {
                                        this.$state.reload();
                                    } else {
                                        this.$state.params["macId"] = Number(result.ID);
                                        this.$state.go(this.$state.current.name, this.$state.params);
                                    }
                                    return this.loadManifestCrew();
                                });
                            });
                        }
                    });
                });
            }

            SaveClose() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'update.crew'
                }, () => {
                    return this.SaveCrew().then(() => {
                        //Call Close
                        this.Close();
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        this.$anchorScroll("topAnchor");
                    });
                });
            }

            Close() {
                this.$state.go("^");
            }

        };

        angular.module("app").controller("manifestCrewUpdateCtrl", controllers.manifest.manifestCrewUpdateCtrl);
    }
}